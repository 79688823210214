import BasePlugin from '../BasePlugin'

export default class ExportInvestmentBudgetOverallExportFromScenario extends BasePlugin {
  async execute () {
    let recordId = this.context.getModel().id
    let me = this.context.getModel()
    this.context.getCard().setLoading(true)
    let card = this.context.getCard()
    let date1 = me.attr_4716_
    let year = date1.substring(0, 4)
    let anotherDate1values = date1.substring(4)
    year = parseInt(year) + 1
    let date2 = year + anotherDate1values

    this.context.$http({
      method: 'get',
      url: `${this.context.$config.api}/registryservice/plugins/execute/ExportInvestmentBudgetsFromScenarioQuery?scenarioId=${recordId}`,
    }).then((response) => {
      let address = `${this.context.$config.api}/etleditor/export/70`
      this.context.$http({
        method: 'post',
        url: address,
        responseType: 'arraybuffer',
        data: {
          'ids': response.data,
          'parameters': {
            'start_year': me.attr_4580_,
            'end_year': me.attr_4581_
          },
          'xref_parameters': [
            {
              'xref_id': 203,
              'parameters': [
                date1,
                date2
              ]
            }
          ]
        }
      }).then(function (response2) {
        card.setLoading(false)
        let blob = new Blob([response2.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        let url = window.URL.createObjectURL(blob)
        window.open(url)
      }, (response) => { card.setLoading(false) })
    })
  }
}
